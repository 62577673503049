import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheckCircle, faTimesCircle } from "@fortawesome/free-solid-svg-icons"
export default function Secfour() {
  return (
    <>
      <Container fluid className="__bg-secondary-sl">
        <Container>
          <Row className="fade-in">
            <Col lg={12}>
              <div className="__wrappe-headline-sl">
                <h2 className="text-center">Pilih Durasi Membershipmu</h2>
              </div>
            </Col>
          </Row>
          {/* <Row className="fade-in __margin-top-sl-50">
            <Col lg={4} md={4}>
              <div className="card __margin-bottom-50">
                <img
                  className="card-img-top img img-fluid __card-top-width"
                  src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovetraining.id/attraction-mastery/1daftar-min-v.png"
                  alt="Card image cap"
                />
                <div className="card-body">
                  <h3 className="card-title">Langkah #1</h3>
                  <p className="card-text"> Klik salah satu tombol daftar</p>
                </div>
              </div>
            </Col>
            <Col lg={4} md={4}>
              <div className="card __margin-bottom-50">
                <img
                  className="card-img-top img img-fluid __card-top-width"
                  src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovetraining.id/attraction-mastery/1daftar-kelas-min-v.png"
                  alt="Card image cap"
                />
                <div className="card-body">
                  <h3 className="card-title">Langkah #2</h3>
                  <p className="card-text">
                    {" "}
                    Pilih salah satu kelas : VIP atau Reguler
                  </p>
                </div>
              </div>
            </Col>
            <Col lg={4} md={4}>
              <div className="card ">
                <img
                  className="card-img-top img img-fluid __card-top-width"
                  src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovetraining.id/attraction-mastery/1isi-form-min-v.png"
                  alt="Card image cap"
                />
                <div className="card-body">
                  <h3 className="card-title">Langkah #3</h3>
                  <p className="card-text">
                    {" "}
                    Lakukan pengisian data dengan benar
                  </p>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="fade-in __margin-top-sl-50">
            <Col lg={4} md={4}>
              <div className="card __margin-bottom-50">
                <img
                  className="card-img-top img img-fluid __card-top-width"
                  src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovetraining.id/attraction-mastery/1transfer-min-v.png"
                  alt="Card image cap"
                />
                <div className="card-body">
                  <h3 className="card-title">Langkah #4</h3>
                  <p className="card-text">
                    {" "}
                    Lakukan pembayaran sebelum batas transfer habis
                  </p>
                </div>
              </div>
            </Col>
            <Col lg={4} md={4}>
              <div className="card __margin-bottom-50">
                <img
                  className="card-img-top img img-fluid __card-top-width"
                  src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovetraining.id/attraction-mastery/1konfirmasi.png"
                  alt="Card image cap"
                />
                <div className="card-body">
                  <h3 className="card-title">Langkah #5</h3>
                  <p className="card-text"> Lakukan Konfirmasi pembayaran</p>
                </div>
              </div>
            </Col>
            <Col lg={4} md={4}>
              <div className="card">
                <img
                  className="card-img-top img img-fluid __card-top-width"
                  src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovetraining.id/attraction-mastery/1cek-email-min-v.png"
                  alt="Card image cap"
                />
                <div className="card-body">
                  <h3 className="card-title">Langkah #6</h3>
                  <p className="card-text">
                    {" "}
                    Cek email (inbox/spam/promotion) untuk lihat info link
                    training
                  </p>
                </div>
              </div>
            </Col>
          </Row> */}
          <Row id="buy-up">
            <Col lg={4} md={4}>
              <div className="pricing py-5">
                <div className="card mb-5 mb-lg-0">
                  <div className="card-body">
                    <h5 className="card-title text-muted text-uppercase text-center">
                      VIP Membership <br /> 1 BULAN
                    </h5>
                    <h6 className="card-price text-center">
                      <del className="__style-red-text"> Rp 212.000</del> <br />
                      Rp 199.000
                    </h6>
                    <h5 className="card-price-v text-center">Hemat 7%</h5>

                    <hr />
                    <ul className="fa-ul">
                      <li>
                        <span className="fa-li">
                          <FontAwesomeIcon
                            icon={faCheckCircle}
                            className="__fa-check-circle-sl"
                            style={{ color: "green" }}
                          />
                        </span>
                        <b>ALL Access VIP Course</b>
                      </li>

                      <li>
                        <span className="fa-li">
                          <FontAwesomeIcon
                            icon={faCheckCircle}
                            className="__fa-check-circle-sl"
                            style={{ color: "green" }}
                          />
                        </span>
                        <b>ALL Access Premium Course</b>
                      </li>
                      <li>
                        <span className="fa-li">
                          <FontAwesomeIcon
                            icon={faCheckCircle}
                            className="__fa-check-circle-sl"
                            style={{ color: "green" }}
                          />
                        </span>
                        <b>ALL Access FREE Course</b>
                      </li>
                      <li>
                        <span className="fa-li">
                          <FontAwesomeIcon
                            icon={faCheckCircle}
                            className="__fa-check-circle-sl"
                            style={{ color: "green" }}
                          />
                        </span>
                        <b>Monthly QnA With Coaches​</b>
                      </li>
                      <li>
                        <span className="fa-li">
                          <FontAwesomeIcon
                            icon={faCheckCircle}
                            className="__fa-check-circle-sl"
                            style={{ color: "green" }}
                          />
                        </span>
                        <b>Exclusive Live Webinar​</b>
                      </li>
                      <li>
                        <span className="fa-li">
                          <FontAwesomeIcon
                            icon={faCheckCircle}
                            className="__fa-check-circle-sl"
                            style={{ color: "green" }}
                          />
                        </span>
                        <b>Support system by LavSquad​</b>
                      </li>
                      <li>
                        <span className="fa-li">
                          <FontAwesomeIcon
                            icon={faCheckCircle}
                            className="__fa-check-circle-sl"
                            style={{ color: "green" }}
                          />
                        </span>
                        <b>Group Challenge​</b>
                      </li>
                    </ul>
                    <h5 className="card-price-v-v text-center">
                      Semua benefit diatas selama 1 bulan
                    </h5>
                    <a
                      href="https://cart.lovecoach.id/product/vip-1-bulan-thr-spesial-offer"
                      target="_blank"
                      className="btn btn-block btn-primary text-uppercase"
                    >
                      Join Sekarang
                    </a>
                    {/* <a
                      href="https://wa.link/d664ke"
                      target="_blank"
                      className="btn btn-block btn-success text-uppercase"
                    >
                      Chat Cs Disini
                    </a> */}
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={4} md={4}>
              <div className="pricing py-5">
                <div className="card mb-5 mb-lg-0">
                  <div className="card-body">
                    <h5 className="card-title text-muted text-uppercase text-center">
                      VIP Membership <br /> 3 BULAN
                    </h5>
                    <h6 className="card-price text-center">
                      <del className="__style-red-text"> Rp 636.000</del> <br />
                      Rp 499.000
                    </h6>
                    <h5 className="card-price-v text-center">Hemat 20%</h5>

                    <hr />
                    <ul className="fa-ul">
                      <li>
                        <span className="fa-li">
                          <FontAwesomeIcon
                            icon={faCheckCircle}
                            className="__fa-check-circle-sl"
                            style={{ color: "green" }}
                          />
                        </span>
                        <b>ALL Access VIP Course</b>
                      </li>

                      <li>
                        <span className="fa-li">
                          <FontAwesomeIcon
                            icon={faCheckCircle}
                            className="__fa-check-circle-sl"
                            style={{ color: "green" }}
                          />
                        </span>
                        <b>ALL Access Premium Course</b>
                      </li>
                      <li>
                        <span className="fa-li">
                          <FontAwesomeIcon
                            icon={faCheckCircle}
                            className="__fa-check-circle-sl"
                            style={{ color: "green" }}
                          />
                        </span>
                        <b>ALL Access FREE Course</b>
                      </li>
                      <li>
                        <span className="fa-li">
                          <FontAwesomeIcon
                            icon={faCheckCircle}
                            className="__fa-check-circle-sl"
                            style={{ color: "green" }}
                          />
                        </span>
                        <b>Monthly QnA With Coaches​</b>
                      </li>
                      <li>
                        <span className="fa-li">
                          <FontAwesomeIcon
                            icon={faCheckCircle}
                            className="__fa-check-circle-sl"
                            style={{ color: "green" }}
                          />
                        </span>
                        <b>Exclusive Live Webinar​</b>
                      </li>
                      <li>
                        <span className="fa-li">
                          <FontAwesomeIcon
                            icon={faCheckCircle}
                            className="__fa-check-circle-sl"
                            style={{ color: "green" }}
                          />
                        </span>
                        <b>Support system by LavSquad​</b>
                      </li>
                      <li>
                        <span className="fa-li">
                          <FontAwesomeIcon
                            icon={faCheckCircle}
                            className="__fa-check-circle-sl"
                            style={{ color: "green" }}
                          />
                        </span>
                        <b>Group Challenge​</b>
                      </li>
                    </ul>
                    <h5 className="card-price-v-v text-center">
                      Semua benefit diatas selama 3 bulan
                    </h5>
                    <a
                      href="https://cart.lovecoach.id/product/vip-3-bulan-thr-spesial-offer"
                      target="_blank"
                      className="btn btn-block btn-primary text-uppercase"
                    >
                      Join Sekarang
                    </a>
                    {/* <a
                      href="https://wa.link/d664ke"
                      target="_blank"
                      className="btn btn-block btn-success text-uppercase"
                    >
                      Chat Cs Disini
                    </a> */}
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={4} md={4}>
              <div className="pricing py-5">
                <div className="card mb-5 mb-lg-0">
                  <div className="card-body">
                    <h5 className="card-title text-muted text-uppercase text-center">
                      VIP Membership <br /> 12 BULAN
                    </h5>
                    <h6 className="card-price text-center">
                      <del className="__style-red-text"> Rp 2.200.000</del>{" "}
                      <br />
                      Rp 1.499.000
                    </h6>
                    <h5 className="card-price-v text-center">Hemat 30%</h5>

                    <hr />
                    <ul className="fa-ul">
                      <li>
                        <span className="fa-li">
                          <FontAwesomeIcon
                            icon={faCheckCircle}
                            className="__fa-check-circle-sl"
                            style={{ color: "green" }}
                          />
                        </span>
                        <b>ALL Access VIP Course</b>
                      </li>

                      <li>
                        <span className="fa-li">
                          <FontAwesomeIcon
                            icon={faCheckCircle}
                            className="__fa-check-circle-sl"
                            style={{ color: "green" }}
                          />
                        </span>
                        <b>ALL Access Premium Course</b>
                      </li>
                      <li>
                        <span className="fa-li">
                          <FontAwesomeIcon
                            icon={faCheckCircle}
                            className="__fa-check-circle-sl"
                            style={{ color: "green" }}
                          />
                        </span>
                        <b>ALL Access FREE Course</b>
                      </li>
                      <li>
                        <span className="fa-li">
                          <FontAwesomeIcon
                            icon={faCheckCircle}
                            className="__fa-check-circle-sl"
                            style={{ color: "green" }}
                          />
                        </span>
                        <b>Monthly QnA With Coaches​</b>
                      </li>
                      <li>
                        <span className="fa-li">
                          <FontAwesomeIcon
                            icon={faCheckCircle}
                            className="__fa-check-circle-sl"
                            style={{ color: "green" }}
                          />
                        </span>
                        <b>Exclusive Live Webinar​</b>
                      </li>
                      <li>
                        <span className="fa-li">
                          <FontAwesomeIcon
                            icon={faCheckCircle}
                            className="__fa-check-circle-sl"
                            style={{ color: "green" }}
                          />
                        </span>
                        <b>Support system by LavSquad​</b>
                      </li>
                      <li>
                        <span className="fa-li">
                          <FontAwesomeIcon
                            icon={faCheckCircle}
                            className="__fa-check-circle-sl"
                            style={{ color: "green" }}
                          />
                        </span>
                        <b>Group Challenge​</b>
                      </li>
                    </ul>
                    <h5 className="card-price-v-v text-center">
                      Semua benefit diatas selama 12 bulan
                    </h5>
                    <a
                      href="https://cart.lovecoach.id/product/vip-12-bulan-thr-spesial-offer"
                      target="_blank"
                      className="btn btn-block btn-primary text-uppercase"
                    >
                      Join Sekarang
                    </a>
                    {/* <a
                      href="https://wa.link/d664ke"
                      target="_blank"
                      className="btn btn-block btn-success text-uppercase"
                    >
                      Chat Cs Disini
                    </a> */}
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="fade-in __margin-top-sl-100-mirror">
            <Col lg={6} className="order-lg-last order-md-last">
              <div className="__wrapper-img-headline-sl-v1">
                <img
                  src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovetraining.id/attraction-mastery/girl-v4.png"
                  alt="image am"
                  className="img img-fluid lazyload"
                />
              </div>
            </Col>

            <Col lg={6}>
              <div className="__wrappe-headline-sl">
                <p>Ladies, seberapa sering kamu bertanya-tanya..</p>
                <p>
                  <i>
                    “Kenapa ya pria yang saya suka gak suka balik? Tapi kok pria
                    yang gak saya suka malah banyak yang ngejar-ngejar?"
                  </i>
                </p>
                <p>
                  <i>
                    “Apa perlu aku bilang duluan kalau aku suka dan cinta sama
                    dia?”
                  </i>
                </p>
                <p>
                  <i>
                    “Tapi kalau diungkapkan takut ditolak dan ujungnya hubungan
                    malah jadi gak enak, Gak diungkapkan, dada terasa sesak..
                    Ugghh!”
                  </i>
                </p>
                <p>Hingga.. Kebimbangan pun menguasai mereka.</p>
                <p>
                  Gak sedikit pula yang akhirnya “pasrah” memilih pria yang
                  tidak terlalu mereka suka karena have no idea terhadap pria
                  yang mereka sukai.
                </p>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <div className="__wrappe-headline-sl">
                <p>
                  Please dont! Saya gak mau kamu terjebak dalam perilaku
                  TERPAKSA MEMILIH YANG ADA! -padahal kamu tidak suka-
                </p>
                <p>
                  Sementara sesungguhnya, kamu benar-benar BISA MEMILIH PRIA
                  YANG KAMU SUKA!
                </p>
                <p>
                  Itulah kenapa, Kami hadir dengan materi training spesifik
                  tentang: MENANAM BENIH CINTA di otak pria.
                </p>
                <p>
                  Sehingga wanita bisa dengan leluasa,{" "}
                  <b>terbebas dari perasaan takut ditolak. </b>
                </p>
                <p>
                  Dan secara diam-diam,{" "}
                  <b>menanam ide romansa pada otak pria. </b>
                </p>
                <p>
                  Sehingga pria <b>bisa tertarik,</b> dan tertantang untuk
                  menaklukan juga membahagiakan Kamu.
                </p>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={12} md={12} sm={12} xs={12}>
              <div className="__bg-white-rounded-sl __margin-top-sl-50">
                <Row className="fade-in">
                  <Col lg={12}>
                    <div className="__wrappe-headline-sl">
                      <h2>Kini Saatnya Kamu Berhenti Dari Kesalahan Ini:</h2>
                    </div>
                  </Col>

                  <Col lg={12} md={12} sm={12} xs={12}>
                    <div className="d-flex">
                      <div className="__width-icon-and-text">
                        <FontAwesomeIcon
                          icon={faTimesCircle}
                          className="__fa-check-circle-sl"
                          style={{ color: "red" }}
                        />
                      </div>
                      <p className="__text-desc-icon-sl">
                        Jadi jaim karena memendam rasa
                      </p>
                    </div>
                  </Col>

                  <Col lg={12} md={12} sm={12} xs={12}>
                    <div className="d-flex">
                      <div className="__width-icon-and-text">
                        <FontAwesomeIcon
                          icon={faTimesCircle}
                          className="__fa-check-circle-sl"
                          style={{ color: "red" }}
                        />
                      </div>
                      <p className="__text-desc-icon-sl">
                        Mengungkapkan perasaan tanpa persiapan
                      </p>
                    </div>
                  </Col>

                  <Col lg={12} md={12} sm={12} xs={12}>
                    <div className="d-flex">
                      <div className="__width-icon-and-text">
                        <FontAwesomeIcon
                          icon={faTimesCircle}
                          className="__fa-check-circle-sl"
                          style={{ color: "red" }}
                        />
                      </div>
                      <p className="__text-desc-icon-sl">
                        Emosional dan gegabah
                      </p>
                    </div>
                  </Col>

                  <Col lg={12} md={12} sm={12} xs={12}>
                    <div className="d-flex">
                      <div className="__width-icon-and-text">
                        <FontAwesomeIcon
                          icon={faTimesCircle}
                          className="__fa-check-circle-sl"
                          style={{ color: "red" }}
                        />
                      </div>
                      <p className="__text-desc-icon-sl">
                        Takut kehilangan gebetan
                      </p>
                    </div>
                  </Col>

                  <Col lg={12} md={12} sm={12} xs={12}>
                    <div className="d-flex">
                      <div className="__width-icon-and-text">
                        <FontAwesomeIcon
                          icon={faTimesCircle}
                          className="__fa-check-circle-sl"
                          style={{ color: "red" }}
                        />
                      </div>
                      <p className="__text-desc-icon-sl">
                        Mengandalkan kecantikan fisik namun sayang hanya
                        mengundang pria hidung belang, fakboi, dan sejenisnya
                      </p>
                    </div>
                  </Col>

                  <Col lg={12}>
                    <div className="__wrappe-headline-sl">
                      <p>
                        Jika Kamu ingin menanamkan ide yang tepat soal romansa,
                        dan ingin dia tertarik BUKAN hanya karena tampang. Tapi
                        karena ketulusan hati untuk bahagiakan Kamu.
                      </p>
                      <p>
                        Tidak ada alasan untuk tidak segera kuasai semua teknik
                        di <br />
                        <b>Attraction Mastery.</b>
                      </p>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </Container>
    </>
  )
}
